import { deprecate } from 'util'; import { deprecate } from 'util';
<template>
  <div class="limits-table">
    <a-table
      class="cash-table"
      size="small"
      :columns="columns"
      :row-key="(record) => record.id"
      :data-source="dataSource"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="$t('placeholder.search')"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined></SearchOutlined></template>
            {{ $t("placeholder.search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ $t("button.reset") }}
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered, column }">
        <search-outlined
          v-if="column.dataIndex === 'name' || column.dataIndex === 'article'"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <filter-outlined
          v-else
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'productCategoryId'">
          {{
            product_categories.filter(
              (pc) => pc.value == record.productCategoryId
            )[0]?.label
          }}
        </template>
        <template v-if="column.dataIndex === 'action'">
          <div class="pointer" @click="showEditModal(record)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2331)">
                <path
                  d="M3 17.46V20.5C3 20.78 3.22 21 3.5 21H6.54C6.67 21 6.8 20.95 6.89 20.85L17.81 9.94L14.06 6.19L3.15 17.1C3.05 17.2 3 17.32 3 17.46ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
                  fill="lightgreen"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2331">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </template>
      </template>
    </a-table>

    <a-modal
      v-model:visible="edit_form.visible"
      :title="$t('modal.changingData')"
      :cancelText="' '"
      @ok="editForm()"
    >
      <a-form
        name="coord_data"
        :model="edit_form"
        class="ant-advanced-search-form"
      >
        <a-row justify="space-between">
          <a-col :md="11" :xs="24">
            <a-form-item>
              <a-input v-model:value="edit_form.name" placeholder="*Название" />
            </a-form-item>
          </a-col>

          <a-col :md="11" :xs="24">
            <a-form-item>
              <a-input
                v-model:value="edit_form.article"
                placeholder="*Артикул"
              />
            </a-form-item>
          </a-col>

          <a-col :md="11" :xs="24">
            <a-form-item>
              <a-select
                class="br-10"
                v-model:value="edit_form.productCategoryId"
                style="width: 100%"
                :options="product_categories"
                placeholder="*Выберите категорию"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import store from "@/store";
import getUserNameById from "@/utils/getUserNameById";
import validateSumm from "@/utils/validateSumm";
import axios from "axios";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { usePagination } from "vue-request";
import config from "../../../config";
// import columns from "../../utils/company/companyCols";
const filterCategory = ref([]);
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: true,
  },
  {
    title: "Название",
    dataIndex: "name",
    customFilterDropdown: true,
    sorter: true,
  },
  {
    title: "Артикуль",
    dataIndex: "article",
    customFilterDropdown: true,
    sorter: true,
  },
  {
    title: "Категория",
    dataIndex: "productCategoryId",
    filters: filterCategory.value,
    sorter: true,
  },
  {
    title: "Действия",
    dataIndex: "action",
  },
];
const queryData = (params) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  };

  return axios.get(config.API_URL + "api/purchase/product/articles", {
    params,
    headers,
  });
};

export default defineComponent({
  components: {
    SearchOutlined,
    FilterOutlined,
  },
  setup(props, { emit }) {
    const state = reactive({
      searchText: "",
      searchedColumn: "",
    });
    const product_categories = ref([]);

    const edit_form = reactive({
      visible: false,
      name: undefined,
      id: undefined,
      article: undefined,
      productCategoryId: undefined,
    });

    const showEditModal = (company) => {
      edit_form.visible = true;
      edit_form.id = company.id;
      edit_form.name = company.name;
      edit_form.article = company.article;
      edit_form.productCategoryId = company.productCategoryId;
    };

    const editForm = async () => {
      emit("editProduct", edit_form, refresh);

      edit_form.visible = false;
      edit_form.name = undefined;
      edit_form.id = undefined;
      edit_form.article = undefined;
      edit_form.productCategoryId = undefined;
    };

    const { data, run, loading, current, pageSize, refresh } = usePagination(
      queryData,
      {
        formatResult: (res) => res.data.articles,
        pagination: {
          currentKey: "page",
          pageSizeKey: "limit",
          totalKey: "data",
        },
      }
    );
    const dataSource = computed(() => data.value?.rows || []);
    const pagination = computed(() => ({
      total: data.value?.count,
      current: current.value,
      pageSize: pageSize.value,
    }));
    const handleTableChange = (pag, filters, sorter) => {
      run({
        limit: pag.pageSize,
        page: pag?.current,
        sortBy: sorter.field,
        sortType: sorter.order === "ascend" ? "ASC" : "DESC",
        ...filters,
      });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };
    const handleReset = (clearFilters) => {
      clearFilters({
        confirm: true,
      });
      state.searchText = "";
    };
    const fetchCategories = async () => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${window.localStorage.getItem("jwt")}`;
      await axios
        .get(config.API_URL + "api/purchase/product/categories")
        .then((res) => {
          res.data.categories.map((st) => {
            product_categories.value.push({
              label: st.name,
              value: st.id,
            });
            filterCategory.value.push({
              text: st.name,
              value: st.id,
            });
          });

          return true;
        })
        .catch((e) => console.log(e));
    };

    return {
      handleSearch,
      handleReset,
      pagination,
      handleTableChange,
      dataSource,
      loading,
      refresh,
      ...toRefs(state),
      columns,
      validateSumm,
      getUserNameById,
      showEditModal,
      edit_form,
      editForm,
      fetchCategories,
      product_categories,
      filterCategory,
    };
  },
  computed: {
    orderCount() {
      return store.state.totalPurchases;
    },
  },
  watch: {
    orderCount() {
      this.refresh();
    },
  },
  async beforeMount() {
    await this.fetchCategories();
  },
});
</script>
