<template>
    <ResetForm/>
</template>
    
<script>
import ResetForm from '../components/ResetForm.vue'
export default {
    components: {
        ResetForm
    }
}
</script>