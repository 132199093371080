<template>
  <a-row>
    <a-col :md="20" :xs="24">
      <p class="titlePage">Добавить товар</p>
      <a-form
        name="cash_search"
        class="ant-advanced-search-form"
        :model="companyModal"
      >
        <a-row justify="space-between">
          <a-col :md="7" :xs="24">
            <a-form-item :name="name">
              <a-input
                class="br-10"
                v-model:value="companyModal.name"
                placeholder="*Название"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="7" :xs="24">
            <a-form-item :name="article">
              <a-input
                class="br-10"
                v-model:value="companyModal.article"
                placeholder="*Артикул"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="7" :xs="24">
            <a-form-item :name="productCategoryId">
              <a-select
                class="br-10"
                v-model:value="companyModal.productCategoryId"
                style="width: 100%"
                :options="product_categories"
                placeholder="*Выберите категорию"
              >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :xs="0"></a-col>

          <a-col :md="7" :xs="24">
            <div class="form-button">
              <button @click="createProduct">Сохранить</button>
            </div>
          </a-col>
          <a-col :xs="24">
            <a-typography-text type="danger"
              >* - обязательные поля</a-typography-text
            >
          </a-col>
        </a-row>
      </a-form>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref } from "vue";
import store from "@/store";
import axios from "axios";
import config from "../../../config.js";

export default {
  setup(props, { emit }) {
    // данные статья

    const companyModal = reactive({
      visible: false,
      name: undefined,
      productCategoryId: undefined,
      article: undefined,
    });

    const product_categories = ref([]);

    const createProduct = async () => {
      emit("createProduct", companyModal);

      companyModal.visible = false;
      companyModal.name = undefined;
      companyModal.productCategoryId = undefined;
      companyModal.article = undefined;
    };

    const fetchCategories = async () => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${window.localStorage.getItem("jwt")}`;
      await axios
        .get(config.API_URL + "api/purchase/product/categories")
        .then((res) => {
          res.data.categories.map((st) => {
            product_categories.value.push({
              label: st.name,
              value: st.id,
            });
          });

          return true;
        })
        .catch((e) => console.log(e));
    };

    return {
      companyModal,
      createProduct,
      product_categories,
      fetchCategories,
    };
  },

  computed: {
    getOrderNumNew() {
      return store.state.cashNum;
    },
  },
  watch: {
    getOrderNumNew() {
      this.refreshCashNum(store.state.cashNum);
    },
  },
  async beforeMount() {
    await this.fetchCategories();
  },
};
</script>

<style scoped>
.form-button {
  margin-top: 10px;
}
.form-button button {
  width: 100%;
  background-color: #72bf44;
  height: 35px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
}

.form-button button:active {
  background-color: #4ba517;
}
.titlePage {
  color: #00360e;
  font-size: 20px;
  font-weight: 550;
  padding-top: 20px;
}
</style>
