<template>
    <CabinetForm/>
</template>

<script>
import CabinetForm from '@/components/CabinetForm.vue';
export default {
    components: {
        CabinetForm
    }
    
}
</script>