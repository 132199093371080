<template>
    <ForgotForm/>
</template>
    
<script>
import ForgotForm from '../components/ForgotForm.vue'
export default {
    components: {
        ForgotForm
    }
}
</script>